<template>
  <div class="profileSavedNotification">
    <div class="profileSavedNotification__item">
      {{ $t('profileSavedNotification.body') }}

      <font-awesome-icon
        icon="check"
        class="profileSavedNotification__itemIcon"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/scss/lib";

.profileSavedNotification {
  text-align: center;
}

.profileSavedNotification__item {
  @include small;
  display: inline-flex;
  align-items: center;
  margin: rem(30px) 0 0 0;
  padding: 10px 16px;
  border-radius: 10px;
  background: $color-beige;
}

.profileSavedNotification__itemIcon {
  margin: 0 0 0 rem(10px);
  font-size: rem(20px);
  color: $color-mint--dark;
}
</style>