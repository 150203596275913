<template>
  <div class="profilePrivacy">
    <div class="profilePrivacy__title">
      {{ $t('profilePrivacy.title') }}
    </div>

    <div class="profilePrivacy__subTitle">
      {{ $t('profilePrivacy.notificationsTitle') }}
    </div>

    <p class="profilePrivacy__body">
      {{ $t('profilePrivacy.notificationsBody') }}
    </p>

    <div class="profilePrivacy__notifications">
      <div class="profilePrivacy__notification">
        <div class="profilePrivacy__notificationToggleWrapper">
          <checkbox-toggle
              v-model="notification_weekly"
              class="profilePrivacy__notificationToggle"
          />
        </div>

        <div class="profilePrivacy__notificationContent">
          <div class="profilePrivacy__notificationTitle">
            {{ $t('profilePrivacy.notificationsWeeklyMailTitle') }}
          </div>

          <p class="profilePrivacy__notificationBody">
            {{ $t('profilePrivacy.notificationsWeeklyMailBody') }}
          </p>
        </div>
      </div>

      <div class="profilePrivacy__notification">
        <div class="profilePrivacy__notificationToggleWrapper">
          <checkbox-toggle
              v-model="notification_personalised"
              class="profilePrivacy__notificationToggle"
          />
        </div>

        <div class="profilePrivacy__notificationContent">
          <div class="profilePrivacy__notificationTitle">
            {{ $t('profilePrivacy.notificationsPersonalisedMailTitle') }}
          </div>

          <p class="profilePrivacy__notificationBody">
            {{ $t('profilePrivacy.notificationsPersonalisedMailBody') }}
          </p>
        </div>
      </div>

      <div class="profilePrivacy__notification">
        <div class="profilePrivacy__notificationToggleWrapper">
          <checkbox-toggle
              v-model="notification_sms"
              class="profilePrivacy__notificationToggle"
          />
        </div>

        <div class="profilePrivacy__notificationContent">
          <div class="profilePrivacy__notificationTitle">
            {{ $t('profilePrivacy.notificationsPersonalisedSMSTitle') }}
          </div>

          <p class="profilePrivacy__notificationBody">
            {{ $t('profilePrivacy.notificationsPersonalisedSMSBody') }}
          </p>
        </div>
      </div>
    </div>

    <div class="profilePrivacy__subTitle">
      {{ $t('profilePrivacy.consentsTitle') }}
    </div>

    <p class="profilePrivacy__body">
      {{ $t('profilePrivacy.consentsBody') }}
    </p>

    <div class="profilePrivacy__notifications">
      <div class="profilePrivacy__notification">
        <div class="profilePrivacy__notificationToggleWrapper">
          <checkbox-toggle
              v-model="medical_consent"
              class="profilePrivacy__notificationToggle"
          />
        </div>

        <div class="profilePrivacy__notificationContent">
          <div class="profilePrivacy__notificationTitle">
            {{ $t('profilePrivacy.consentsMedicalTitle') }}
          </div>

          <p class="profilePrivacy__notificationBody">
            {{ $t('profilePrivacy.consentsMedicalBody') }}
          </p>
        </div>
      </div>
      <div
          class="profilePrivacy__notification"
          v-if="champ"
      >
        <div class="profilePrivacy__notificationToggleWrapper">
          <checkbox-toggle
              v-model="tracking_consent"
              class="profilePrivacy__notificationToggle"
          />
        </div>

        <div class="profilePrivacy__notificationContent">
          <div class="profilePrivacy__notificationTitle">
            {{ $t('profilePrivacy.consentsTrackingTitle') }}
          </div>

          <p class="profilePrivacy__notificationBody">
            {{ $t('profilePrivacy.consentsTrackingBody') }}
          </p>
        </div>
      </div>
    </div>

    <profile-saved-notification v-if="showSavedNotification"/>
  </div>
</template>

<script>
import {defineAsyncComponent, markRaw} from 'vue';
import {mapState, mapActions} from 'vuex';
import ProfileSavedNotification from '@/components/profile/ProfileSavedNotification';
import CheckboxToggle from '@/components/CheckboxToggle';
import {ModalBus} from '@/eventBus';

const MedicalConsentModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/MedicalConsentModal' /* webpackChunkName: "medicalConsentModal" */),
}));

const TrackingConsentModal = markRaw(defineAsyncComponent({
  loader: () => import('@/components/TrackingConsentModal' /* webpackChunkName: "trackingConsentModal" */),
}));

export default {
  components: {
    ProfileSavedNotification,
    CheckboxToggle,
  },

  data() {
    return {
      showSavedNotification: false,
    };
  },

  computed: {
    ...mapState('member', ['privacySettings']),
    ...mapState('experimental', ['champ']),

    medical_consent: {
      get() {
        return this.privacySettings.medical_consent;
      },
      async set(value) {
        this.onMedicalConsentToggleChange(value);
      },
    },

    tracking_consent: {
      get() {
        return this.privacySettings.tracking_consent;
      },
      async set(value) {
        this.onTrackingConsentToggleChange(value);
      },
    },

    notification_personalised: {
      get() {
        return this.privacySettings.notification_personalised;
      },
      set(value) {
        this.updatePrivacySettings({notification_personalised: value})
            .then(this.showSaveNotification);
      },
    },

    notification_sms: {
      get() {
        return this.privacySettings.notification_sms;
      },
      set(value) {
        this.updatePrivacySettings({notification_sms: value})
            .then(this.showSaveNotification);
      },
    },

    notification_weekly: {
      get() {
        return this.privacySettings.notification_weekly;
      },
      set(value) {
        this.updatePrivacySettings({notification_weekly: value})
            .then(this.showSaveNotification);
      },
    },
  },

  created() {
    this.fetchPrivacySettings();
  },

  methods: {
    ...mapActions('member', ['fetchPrivacySettings', 'updatePrivacySettings']),

    async onMedicalConsentToggleChange(value) {
      if (!value) {
        ModalBus.emit('open', {
          component: MedicalConsentModal,
          closeCallback: this.onModalClose,
          props: {
            enableConsent: false,
          },
        });

        return;
      }

      await this.updatePrivacySettings({medical_consent: value})
          .catch(() => this.medical_consent = !value);

      this.showSaveNotification();
    },

    async onTrackingConsentToggleChange(value) {
      if (!value) {
        ModalBus.emit('open', {
          component: TrackingConsentModal,
          closeCallback: this.onModalClose,
          props: {
            enableConsent: false,
          },
        });

        return;
      }

      await this.updatePrivacySettings({tracking_consent: value})
          .catch(() => this.tracking_consent = !value);

      this.showSaveNotification();
    },

    onModalClose() {
      this.showSaveNotification();
    },

    showSaveNotification() {
      this.showSavedNotification = true;

      setTimeout(() => {
        this.showSavedNotification = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/lib";

.profilePrivacy__title {
  @include heading-5;
  margin: 0 0 rem(24px) 0;
}

.profilePrivacy__subTitle {
  @include section--bold;
  margin: rem(32px) 0 rem(18px) 0;
  padding: 0 0 rem(4px) 0;
  color: $color-grey--medium;
  border-bottom: 1px solid $color-grey--light;
}

.profilePrivacy__body {
  @include small;
  margin: 0 0 rem(24px) 0;
  max-width: 44ch;
}

.profilePrivacy__notification {
  display: flex;
  margin: 0 0 rem(28px) 0;

  &:last-child {
    margin: 0;
  }
}

.profilePrivacy__notificationToggleWrapper {
  margin: 0 rem(16px) 0 0;
}

.profilePrivacy__notificationTitle {
  @include small;
  margin: 0 0 rem(6px) 0;
}

.profilePrivacy__notificationBody {
  @include note;
  max-width: 270px;
  color: $color-grey;
}
</style>